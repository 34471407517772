<template>
  <Faq
    :items="faq"
    :loading="pageDataLoading"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Faq from 'organisms/FAQ/FAQ'

export default {
  components: {
    Faq
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters(['faq', 'pageDataLoading', 'language'])
  },
  watch: {
    language: function () {
      this.loadFAQs()
    }
  },
  mounted () {
    this.loadFAQs()
  },
  methods: {
    ...mapActions(['getFAQ']),
    loadFAQs () {
      this.getFAQ(this.language)
    }
  }
}
</script>

<style lang="scss"></style>
